declare const window: any
// @ts-ignore
import $ from 'jquery'
// @ts-ignore
window.$ = $
// @ts-ignore
window.self.$ = $

import 'kernel/libs/nl2br'
import helper from 'kernel/helper/helper'
window.helper = helper
Vue.prototype.$helper = helper

import vueRouteChangePrevent from 'kernel/libs/vueRouteChangePrevent'
window.vueRouteChangePrevent = vueRouteChangePrevent

import crossTab from 'kernel/libs/crossTab'
window.crosstab = crossTab

import zIndexConfig from 'config/zIndex'
window.$zIndexConfig = zIndexConfig
Vue.prototype.$zIndexConfig = zIndexConfig

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import vuetify from './plugins/vuetify'
import language from 'kernel/libs/language'
language.init()
import bootstrap from 'modules/bootstrap'

import i18n from 'i18n'
Vue.config.productionTip = false
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

class website {
  async start() {
    window.bootstrap = bootstrap
    await window.bootstrap.init()
    await this.startVue()
  }

  async startVue() {
    const router = (await import('./router')).default
    window.rootVm = new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),
      created() {
        window.crosstab.on('setToken', (event: AnyObject, tabId: any) => {
          if(tabId == event.origin) return
          this.$tokenStore.set(event.data.token)
        })

        window.crosstab.on('cleanToken', (event: AnyObject, tabId: any) => {
          if(tabId == event.origin) return
          this.$tokenStore.clean()
        })

        this.setupI18n()
      },
      methods: {
        setupI18n() {
          this.$i18n.locale = language.get()
          this.$vuetify.lang.current = language.get()
          this.setupModuleI18n()

          this.$vuetify.lang.t = (key, ...params) => this.$t(key, params)

          // @ts-ignore
          Vue.filter('t', (...args) => this.$t(...args))
          // @ts-ignore
          Vue.filter('tc', (...args) => this.$tc(...args))
          // @ts-ignore
          Vue.filter('te', (...args) => this.$te(...args))
        },
        // 合併所有模組的i18n目錄語系
        setupModuleI18n() {
          const moduleLocales = require.context('modules', true, /i18n\/[A-Za-z0-9-_,\s]+\.js$/i)
          moduleLocales.keys().forEach(key => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i)
            if (matched && matched.length > 1) {
              const locale = matched[1]
              const moduleI18n = moduleLocales(key).default
              this.$i18n.mergeLocaleMessage(locale, moduleI18n)
            }
          })
        }
      },
    }).$mount('#app')
  }
}

(new website()).start()