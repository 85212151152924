<template>
  <v-snackbar
    right
    v-model="status"
    :timeout="timeout"
  >
    {{text}}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="green"
        text
        v-bind="attrs"
        @click="status = false"
      >
        &times;
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="babel" type="text/babel">
import { events } from './events'
export default {
  data: () => ({
    status: false,
    options: {},
    listener: null,
  }),
  created() {
    this.setupListener()
  },
  methods: {
    setupListener() {
      this.listener = events
      this.listener.$on('active', this.active)
    },
    active(options) {
      this.options = options
      this.status = true
    },
    onClose() {
      this.options = {}
    },
  },
  computed: {
    text() {
      if(typeof this.options != 'object') {
        return this.options
      }

      return this.options.text || ''
    },
    timeout() {
      const timeout = parseInt(this.options.timeout)
      if(isNaN(timeout) === false) return timeout
      return 5000
    },
  },
  watch: {
    status() {
      if(!this.status) this.onClose()
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>