export default {
  'page.contact': '客服訊息',
  'page.app-config.contact': '客服訊息設定',
  'module.contact': '客服訊息',

  'ROLE_CONTACT': '客服管理',

  // data
  'contact.data.status.created': '新建立',
  'contact.data.status.in-progress': '處理中',
  'contact.data.status.closed': '結案',
  'contact.data.type': '訊息類型',
  'contact.data.type.default': '聯絡我們',
  'contact.data.type.software': '軟體開發',
  'contact.data.code': '查詢碼',
  'contact.data.note': '處理備註',
  'contact.search_keyword': '輸入標題、姓名、電話號碼、Email搜尋',

  // form
  'contact.form_block.comments': '訊息',

  // others
  'contact.guest_message.disabled_reply': '訪客訊息無法回覆',
}
