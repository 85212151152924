export default {
  'page.member': '會員',
  'module.member': '會員',

  // role
  'ROLE_ADMIN': '會員管理',

  // data
  'member.data.register_type': '註冊方式',
  'member.data.locked': '黑名單',
  'member.data.locked.true': '啟用',
  'member.data.locked.false': '停用',
  'member.data.birthday': '生日',

  // sso
  'sso.default': '系統建立',
  'sso.email': 'Email',
  'sso.google': 'Google',
  'sso.facebook': 'Facebook',
  'sso.line': 'Line',
  'sso.apple': 'Apple',

  // form
  // 'member.form_block.foobar': 'foobar',

  // others
  'member.search_keyword': '輸入姓名、Email、電話搜尋',
  'member.delete.confirm': '會員一旦刪除, 將會無法再回復',
  'member.login.confirm': '請確保這個瀏覽器前台的頁籤全部關閉，以免發生登入意外狀況',
}
