module.exports = {
  name: 'Higher Eagle',
  manifest: {
    themeColor: '#1190cb',
    backgroundColor: '#000000',
  },
  locales: {
    list: ['zh-TW', 'zh-CN', 'en-US'],
    defaultLocale: 'zh-TW',
    defaultTimeZone: 'Asia/Taipei',
  },
  seo: {
    keywords: 'Higher Eagle',
    description: `Lock and Load already`,
  },
  sitemap: {
    types: [
      'page',
    ],
  },
}
