// @ts-ignore
import Vue from 'vue'
import { events } from './events'
import snackbar from './snackbar.vue'
class Installer {
  protected isInstalled: boolean
  protected emitter: Vue

  constructor() {
    this.isInstalled = false
    this.emitter = events
  }

  install() {
    if(this.isInstalled) return
    this.isInstalled = true
    Vue.component('eagle-snackbar', snackbar)
    Vue.prototype.$snackbar = {
      active: (options: any) => {
        this.emitter.$emit('active', options)
      }
    }
  }
}

export default new Installer()
export interface SnackbarInterface {
  active: (option: any) => void
}